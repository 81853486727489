import { application } from '../application'

// Shared
import Shared_CookiesRequiredController from '../shared/cookies_required_controller'
application.register('cookies-required', Shared_CookiesRequiredController)

import Shared_FormController from '../shared/form_controller'
application.register('form', Shared_FormController)

// Checkout
import Checkout_SubscriptionController from '../checkout/subscription_controller'
application.register('subscription', Checkout_SubscriptionController)
