import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="detached-modal"
export default class extends Controller {
  open({ params }) {
    // Sends an event that a detached modal will listen to

    // Example setup:

    // Open modal button
    // data-controller: 'detached-modal'
    // data-action: "click->detached-modal#open"
    // data-param: "data-detached-modal-name-param="name-of-modal"

    // Modal to open
    // data-controller: 'basic-modal'
    // data-action: 'detached-modal:open-name-of-modal@window->basic-modal#open'

    // Fired event: 'open-name-of-modal'
    this.dispatch(`open-${params.name}`)
  }
}
