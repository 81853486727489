import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="cookies-required"
export default class extends Controller {
  initialize() {
    this.checkIfCookiesAreEnabled()
  }

  checkIfCookiesAreEnabled() {
    if (!navigator.cookieEnabled) {
      this.dispatch('open-modal')
    }
  }
}
